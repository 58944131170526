var app = app || {};

/**
 * app.evn
 * @author https://davidwalsh.name/pubsub-javascript
 * @author Pascal Franzke <pf@linienflug.design>
 * @example
 * app.evn.publish('/page/load', {
 *     url: '/some/url/path' // any argument
 * });
 * var subscription = app.evn.subscribe('/page/load', function(obj) {
 *  	// Do something now that the event has occurred
 * });
 *
 * // ...sometime later where I no longer want subscription...
 * subscription.remove();
 *
 *
 */
app.evn = (function () {

    'use strict';

    var debug = window.location.href.match(/.dev|.local|localhost/) ? true : false;
    var topics = {};
    var hOP = topics.hasOwnProperty;

    return {
        subscribe: function (topic, listener) {
            if (debug) console.log('%c' + '/app/evn:subscribe | ' + topic, 'background-color: #2b3846; color: #ffa300; padding: 3px .5em;');
            // Create the topic's object if not yet created
            if (!hOP.call(topics, topic)) topics[topic] = [];
            // Add the listener to queue
            var index = topics[topic].push(listener) - 1;

            // Provide handle back for removal of topic
            return {
                remove: function () {
                    delete topics[topic][index];
                }
            };
        },
        publish: function (topic, info) {
            if (debug) console.log('%c' + '/app/evn:publish | ' + topic, 'background-color: #2b3846; color: #ffa300; padding: 3px .5em;');
            // If the topic doesn't exist, or there's no listeners in queue, just leave
            if (!hOP.call(topics, topic)) return;

            // Cycle through topics queue, fire!
            topics[topic].forEach(function (item) {
                item(info !== undefined ? info : {});
            });
        }
    };
})();

/**
 * main Loads all submodules
 * @author Pascal Franzke <pascal.franzke@neuwaerts.de>
 * @author Pascal Franzke <pascal.franzke@googlemail.com>
 * @author Pascal Franzke <pf@linienflug.design>
 * @namespace main
 * @memberof app
 * @param {window} Object - The window Object
 * @return {object}
 */
/* jshint -W003 */
/* globals console */
/* globals WebFont */
app.main = (function (window) {
    'use strict';
    var logcss = 'background-color: #2b3846; color: #ffa300; padding: .32em .5em;';
    var debug = true,
        elements = [],
        modules = {};

    var throttle = function (type, obj) {
        obj = obj || window;
        var running = false;
        var func = function () {
            if (running) {
                return;
            }
            running = true;
            requestAnimationFrame(function () {
                app.evn.publish(type);
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    throttle('resize');
    throttle('focus');
    throttle('blur');

    /*
     * HasOwnProperty-helper
     */
    var hasOwn = function _hasOwn(obj, prop) {
        return Object.prototype.hasOwnProperty.call(obj, prop);
    };

    var inIframe = function _inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    };

    var runModuleFn = function _runModuleFn(modulename, functionName, el) {
        if (hasOwn(app[modulename], functionName) || typeof app[modulename][functionName] === 'function') {
            var fn = app[modulename][functionName];
            if (typeof el !== 'undefined' && typeof el.length !== 'undefined' && el.length > 1) {
                for (var i in el) {
                    app.evn.publish('/app/'+modulename+':'+functionName+'|before');
                    fn(el[i]);
                    app.evn.publish('/app/'+modulename+':'+functionName);
                }
            } else {
                app.evn.publish('/app/'+modulename+':'+functionName+'|before');
                fn(el);
                app.evn.publish('/app/'+modulename+':'+functionName);
            }
        } else {
            if (debug) console.log('%c' + '/app:runModuleFn' + ' | ' + (inIframe() ? '/iframe' : '') + '/app/' + modulename + ':' + functionName+ ' is missing', logcss);
        }
        return true;
    };

    /**
     * initElements
     * gets all elements inside elnode or document and adds them to app.main.elements[modulename] (modulename comes from data-module)
     */
    var getElements = function _getElements(elnode) { // elnode ist der container
        var els = elnode ? elnode.querySelectorAll('[data-module]') : document.querySelectorAll('[data-module]');
        var out = [];
        var len = els.length;
        for (var i = 0; i < len; i++) {
            var el = els[i];
            var mods = el.getAttribute('data-module').split(',');
            for (var j in mods) {
                var mod = mods[j];

                if (typeof elements[mod] === 'undefined') {
                    elements[mod] = [];
                }
                if (typeof out[mod] === 'undefined') {
                    out[mod] = [];
                }
                if (elements[mod].indexOf(el) < 1) {
                    elements[mod].push(el);
                }
                if (out[mod].indexOf(el) < 1) {
                    out[mod].push(el);
                }
            }
        }
        return out;
    };
    /**
     * init main
     * @return {bool}
     * @example
     *  app.main.init()
     */
    var init = function __init() {
        app.evn.publish('/app:init|before');

        // replace no-js with js
        (function(html) {
            html.className = html.className.replace(/\bno-js\b/, 'js');
        })(document.documentElement);

        // is mobile?
        (function (a) {
            var isMobile = false;
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) isMobile = true
            document.documentElement.className = document.documentElement.className + (isMobile ? ' mobile' : ' desktop');
        })(navigator.userAgent || navigator.vendor || window.opera);

        area();
        app.evn.publish('/app:init');
        return true;
    };

    /**
     * init modules in area
     * @param  {DOMElement} namespace
     * @return {bool}
     * @example
     *  app.main.init()
     */
    var area = function _(namespace){
        app.evn.publish('/app:area|before');
        var els = getElements((namespace && namespace.nodeName ? namespace : undefined)); // bereitet var elements[] vor
        Object.keys(els).forEach(function(mod){
            var elements = els[mod];
            try {
                if(typeof app[mod] === 'undefined'){
                    if (debug) console.log('%c' + '/app/'+mod+' do not exists', logcss);
                    return false;
                }

                if(typeof modules[mod] === 'undefined'){
                    runModuleFn(mod, 'init');
                    modules[mod] = true;
                }

                elements.forEach(function(el){
                    runModuleFn(mod, 'new', el);
                });
            } catch(err){
                console.error(err);
            }
        });
        app.evn.publish('/app:area');
    }


    /*
     * loadFonts
     *
     * @example
     * main.loadFonts({
     *     custom: {
     *         families: ['icomoon'],
     *         urls: ['/site/assets/styles/fonts.min.css']
     *     }
     * });
     */
    function loadFonts(ob) {
        if (typeof (WebFont) === 'undefined') {
            setTimeout(loadFonts, 200);
            return;
        }
        WebFont.load(ob);
    };

    return {
        logcss: logcss,
        init: init,
        area: area,
        loadFonts: loadFonts
    };
})(window);

//console.log('%c' + 'Linienflug Design ✈ Weil bewegt bewegt.', 'line-height: 30px; font-size: 18px; border-radius: .25em;background-color: #2b3846; color: #ffa300; padding: 3px .5em;');
document.addEventListener('DOMContentLoaded', app.main.init);
