/*
 * Minimal classList shim for IE 9
 * By Devon Govett
 * MIT LICENSE
 */
 /*jshint -W016 */
 /*jshint -W030 */
if (!('classList' in document.documentElement) && Object.defineProperty && typeof HTMLElement !== 'undefined') {
    Object.defineProperty(HTMLElement.prototype, 'classList', {
        get: function () {
            'use strict';
            var self = this;

            function update(fn) {
                return function (value) {
                    var classes = self.className.split(/\s+/),
                        index = classes.indexOf(value);

                    fn(classes, index, value);
                    self.className = classes.join(' ');
                };
            }

            var ret = {
                add: update(function (classes, index, value) {
                    ~index || classes.push(value);
                }),

                remove: update(function (classes, index) {
                    ~index && classes.splice(index, 1);
                }),

                toggle: update(function (classes, index, value) {
                    ~index ? classes.splice(index, 1) : classes.push(value);
                }),

                contains: function (value) {
                    return !!~self.className.split(/\s+/).indexOf(value);
                },

                item: function (i) {
                    return self.className.split(/\s+/)[i] || null;
                }
            };

            Object.defineProperty(ret, 'length', {
                get: function () {
                    return self.className.split(/\s+/).length;
                }
            });

            return ret;
        }
    });
}
