var app = app || {};
/**
 * @name slideFade
 * @description navigation function, witch just adds the navigation
 * @author Rolf Braase
 * @memberof app
 * @namespace navigation
 * @return Object - Die Public-API des Modules
 */
app.slideFade = (function() {

    'use strict';

    /**
     * init()
     *
     * @param {array} elements[{HTMLElement}]
     * @return {boolen}
     * @memberOf app.navigation
     */
    var init = function _init() {

        return true;
    };

    /**
     * newEl(element)
     *
     * @pubic new
     * @param {HTMLElement} element
     * @memberOf app.navigation
     */
    var newEl = function _newEl(el) {
        if (!el) {
            return false;
        }
        var $this = $(el);

        var $images = $this.children();

        var itemCount = $images.length;

        $this.addClass(el.length);

        //console.log(itemCount);
        //console.log(el.length);
        $images.first().addClass('first');
        $images.last().addClass('last');

        //console.log($images);

        var playing;

        var currentImage = 1;

        function show(img) {
            var item = '.item__' + img;
            //console.log(item);
            var $item = $this.find(item);
            //var $itemLast = $('.item__' + (img - 1));

            if (img == 1) {
                $images.removeClass('fadeOut');
                $item.addClass('fadeOut');
            }

            if (!$item.hasClass('fadeOut')) {
                $item.addClass('fadeOut');
            }

        }

        // start autoplay with 1s per image
        function play() {
            if (!playing) {
                playing = setInterval(function() {
                    show(currentImage + 1);
                    currentImage++;
                    currentImage %= itemCount;
                }, 7000);
            }
        }

        play();


    };
    return {
        init: init,
        new: newEl
    };

})();
