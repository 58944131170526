var app = app || {};
/**
 * @name configurator
 * @description build your mamo
 * @author Rolf Braase
 * @memberof app
 * @namespace navigation
 * @return Object - Die Public-API des Modules
 */
app.configurator = (function() {

    'use strict';

    var options = {
        owlClass: 'owl-carousel',
        owlFixClass: 'owl-fix' //sorgt dafür das das Owlcarousel nicht standartmäíg ausgeblendet wird und somit springt!
    };
    /**
     *	Default Owl Option
     */
    var owloptions = {
        nav: true,
        loop: false,
        dots: true,
        items: 1,
        startPosition: 1,
        margin: 60,
        autoplay: false,
        mouseDrag: true,
        autoHeight: false,
        autoplayTimeout: 4000,
        autoplaySpeed: 500,
        smartSpeed: 500,
        autoplayHoverPause: true,
        center: true,

        navText: [
            '<span class="audible">Back</span><span class="icon icon-caret-left nav__icon"></span>',
            '<span class="audible">Next</span><span class="icon icon-caret-right nav__icon"></span>'
        ]

    };
    /**
     *	typebase Owl Options
     */
    var owloptionsTypes = {
        noAutoplay: {
            autoplay: false
        },
    };

    var getOptionFor = function getOptionFor(type) {
        if (typeof owloptionsTypes[type] !== 'object') {
            return owloptions;
        }
        return merge(owloptions, owloptionsTypes[type]);
    };




    /**
     * init()
     *
     * @param {array} elements[{HTMLElement}]
     * @return {boolen}
     * @memberOf app.navigation
     */
    var init = function _init() {

        return true;
    };

    /**
     * newEl(element)
     *
     * @pubic new
     * @param {HTMLElement} element
     * @memberOf app.navigation
     */
    var timeout;
    var endIntroTimer;
    var slideCount = [];

    var newEl = function _newEl(el) {
        if (!el) {
            return false;
        }

        var $this = $(el);

        var $build = $('.conf-build-js');
        var $rotate = $('.mm__rotate-js');

        initSlider($this);

        //console.log($this.attr('class'));

        if (!$this.hasClass('tx-mm-mamo-configurator')) {
            intro($this, $build, endIntroTimer);
        }



        $rotate.on('click', function() {
            if($('.mm__headrest__item').hasClass('mm__headrest__item--rotate')){
                $('.mm__headrest__item').removeClass('mm__headrest__item--rotate');
            }else{
                $('.mm__headrest__item').addClass('mm__headrest__item--rotate');
            }
        });

        //build button click

        $build.on('click', function() {
            if ($this.hasClass('build')) {
                $this.removeClass('build');
                $(this).text('Zusammenbauen');
                $('.configurator').find('.owl-nav').fadeIn('fast');

                if ($this.hasClass("do_intro")) {
                    clearTimeout(endIntroTimer);
                    endIntro($this, $build);
                }

            } else {
                $this.addClass('build');
                $(this).text('Konfigurieren');
                $('.configurator').find('.owl-nav').fadeOut('fast');
            }
        });


        getRandomSet($this);

    };



    var intro = function intro($this, $build) {
        var $this = $this;
        var $build = $build;

        setTimeout(function() {
            $build.click();
        }, 75);

        setTimeout(function() {
            $this.addClass("start-js");
        }, 900);

        endIntroTimer = setTimeout(function() {
            endIntro($this, $build);
            $build.click();
        }, 8100);
    }

    var endIntro = function endIntro($this, $build) {
        $this.removeClass("do_intro");
        $this.removeClass("start-js");
        clearTimeout(endIntroTimer);
    }

    var debounce = function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this,
                args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    var checkWait = function checkWait(item) {
        var $this = $(item);
        if ($(item).hasClass('wait')) {
            clearTimeout(timeout);
            timeout = setTimeout(checkWait($item), 250);
        } else {
            timeout = null;
            var typeBasedOptions = getOptionFor($(item).attr('data-type'));
            var $owl = $(item).find('.slideOption').owlCarousel(typeBasedOptions);
            $owl.trigger('to.owl.carousel',[0,500]);
            //$owl.trigger('prev.owl.carousel',[500]);
            return $owl;
        }
    }

    var initSlider = function initSlider($this) {
        /// init owl



        function random(owlSelector) {
            owlSelector.children().sort(function() {
                return Math.round(Math.random()) - 0.5;
            }).each(function() {
                $(this).appendTo(owlSelector);
            });
        }
        $this.find('.slideOption').each(function(index) {
            random($(this));
            slideCount[index] = $(this).children().length;
        });


        var $owl = checkWait($this);

        $owl.on('initialize.owl.carousel initialized.owl.carousel ' +
                'initialize.owl.carousel initialize.owl.carousel ' +
                'resize.owl.carousel resized.owl.carousel ' +
                'refresh.owl.carousel refreshed.owl.carousel ' +
                'update.owl.carousel updated.owl.carousel ' +
                'drag.owl.carousel dragged.owl.carousel ' +
                'translate.owl.carousel translated.owl.carousel ' +
                'to.owl.carousel changed.owl.carousel',
                function(e) {
                    //console.log(e.type);
                  if (e.type == "translated") {
                       //console.log(e);
                        var idx = e.item.index;
                    //    var $class = $(e).find('.mm__panel').attr('class');
                       //console.log(e.currentTarget.parentNode.className);
                       var panelID = $('.'+e.currentTarget.parentNode.className).find('.owl-item').eq(idx);
                       var panelItem = panelID.find('.mm__panel').attr('rel');
                       //console.log(panelItem);
                       if(panelItem != undefined){
                           $this.removeClass('mm__panel--type1')
                           .removeClass('mm__panel--type2')
                           .removeClass('mm__panel--type3')
                           .removeClass('mm__panel--type4')
                           .addClass(panelItem);
                       }
                  }
                });



        //console.log(slideCount);

    };

    var random = function random(start, end) {
        return Math.floor(Math.random() * (end - start + 1)) + start;
    }

    var getRandomSet = function getRandomSet(item) {
        var $this = item;
        $('.btnRandom-js').click(function() {
            $this.find('.slideOption').each(function(index) {
                //console.log(random(1, slideCount[index]));
                $(this).trigger('to.owl.carousel', [random(1, slideCount[index])]);
                //$(this).trigger('next.owl.carousel');
            });
        });
    }

    /**
     * slider->destroy(element)
     * removes owl carousel on given element
     *
     * @private
     * @param {HTMLElement} element
     * @memberOf app.slider
     */
    var destroy = function _destroy(element) {
        return $(element).data('owlCarousel').destroy();
    };



    return {
        init: init,
        new: newEl
    };

})();
