var app = app || {};
/**
 * @name waypoint
 * @description init waypoints scrolling elements
 * @author Rolf Braase <rolf@mamamotion.de>
 * @memberof app
 * @namespace waypoint
 * @return Object - Die Public-API des Modules
 */
app.waypoint = (function () {

    'use strict';



    /**
     * init()
     *
     * @param {array} elements[{HTMLElement}]
     * @return {boolen}
     * @memberOf app.example
     */
    var init = function _init() {

        //console.log('ex');
        return true;
    };


    var optionsTypes = {
      wpLeft: {
        autoplay: false
      },
    };

    /**
     * newEl(element)
     *
     * @pubic new
     * @param {HTMLElement} element
     * @memberOf app.example
     */



    var newEl = function _newEl(el) {

        if (!el) {
            return false;
        }

        var $el = $(el);

        var typeBasedOptions = $el.attr('data-type');


        $el.waypoint(function() {
            if ($el.hasClass('waypoint--in')) {
                $el.removeClass('waypoint--in');
            }else {
                $el.addClass('waypoint--in');
            }


      }, { offset: '80%' });

        // $(el).waypoint(function(){
        //     $(el).addClass('waypont');
        // },{
        //       handler: function() {
        //         //notify('Hit midpoint of my context')
        //         console.log('wtf');
        //         $(el).addClass('waypont');
        //       },
        //      // context: '#overflow-scroll-offset',
        //       offset: '20px'
        //   });

        //el.classList.add('gangnam-style');
    };
    return {
        init: init,
        new: newEl
    };

})();
