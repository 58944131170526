var app = app || {};
/**
 * @name navigation
 * @description navigation function, witch just adds the navigation
 * @author Rolf Braase
 * @memberof app
 * @namespace navigation
 * @return Object - Die Public-API des Modules
 */
app.navigation = (function() {

    'use strict';

    /**
     * init()
     *
     * @param {array} elements[{HTMLElement}]
     * @return {boolen}
     * @memberOf app.navigation
     */
    var init = function _init() {

        return true;
    };

    /**
     * newEl(element)
     *
     * @pubic new
     * @param {HTMLElement} element
     * @memberOf app.navigation
     */
    var newEl = function _newEl(el) {
        if (!el) {
            return false;
        }
        console.log(el);
        var $this = $(el);

        var $burger = $('.burger');

        var $navwrapper = $('.navigation__wrapper');

        $burger.on('click', function() {
            opencloseCheck(this);
        });

        // $nav.find('.nav__link').on('click', function() {
        //     opencloseCheck($burger);
        // });

        var opencloseCheck = function opencloseCheck(elem) {
            if ($(elem).hasClass('burger--opened')) {
                $(elem).removeClass('burger--opened');
                $navwrapper.removeClass('navigation__wrapper--open');
            } else {
                $(elem).addClass('burger--opened');
                $navwrapper.addClass('navigation__wrapper--open');
            }
        }

    };
    return {
        init: init,
        new: newEl
    };

})();
