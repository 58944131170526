var app = app || {};
/**
 * @name Accordion
 * @description Accordion function, witch just adds an Accordion
 * @author Rolf Braase
 * @memberof app
 * @namespace Accordion
 * @return Object - Die Public-API des Modules
 */
app.accordion = (function() {

    'use strict';

    /**
     * init()
     *
     * @param {array} elements[{HTMLElement}]
     * @return {boolen}
     * @memberOf app.navigation
     */
    var init = function _init() {

        return true;
    };

    /**
     * newEl(element)
     *
     * @pubic new
     * @param {HTMLElement} element
     * @memberOf app.navigation
     */
    var newEl = function _newEl(el) {
        if (!el) {
            return false;
        }

        var $this = $(el);
        var $header = $this.find('.accordion__header');
        var $content = $this.find('.accordion__content');
        var closedClass = "accordion__content--closed";
        var closedClassHead = "accordion__header--closed";

        $header.on('click',function(){
            if ($content.hasClass(closedClass)) {
                $content.removeClass(closedClass);
                $header.removeClass(closedClassHead);
            }else{
                $content.addClass(closedClass);
                $header.addClass(closedClassHead);
            }
        });



    };
    return {
        init: init,
        new: newEl
    };

})();
